import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import RCSlider from 'rc-slider';
import 'rc-slider/assets/index.css';

import './styles.css'

const Slider = ({min, max, value, onChange}) => {
  return (
    <div className='slider'>
      <FontAwesomeIcon icon={icon({name: 'angle-left', family: 'classic', style: 'solid'})} />
      <RCSlider
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        railStyle={{
          backgroundColor: "#EEEEEE"
        }}
        activeDotStyle={{
          borderColor: "#FFF"
        }}
      />
      <FontAwesomeIcon icon={icon({name: 'angle-right', family: 'classic', style: 'solid'})} />
    </div>
  )
}

export default Slider;