import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";

import { db } from '../../App';

import './styles.css'

import Navigation from '../../components/navigation';
import TextInput from '../../components/textinput';
import Button from '../../components/button';
import Header from '../../components/header';
import StudiesTable from '../../components/studiesTable';

import Logo from '../../assets/logo.png'

const Studies = () => {
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();

  async function getStudies() {
    const q = query(collection(db, "simulation"), where("creator", "==", localStorage.getItem('userId')), orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);
    setTableData(querySnapshot);
  }

  useEffect(() => {
    getStudies();
  }, []);

  return (
    <div className='home'>
      <div className='home_container'>
        <Navigation/>
        <div className='home_right_container'>
          <Header/>
          <div className='home_block'>
            <div className='home_title_contrainer'>
              <div className='home_title_right_contrainer'>
                <div className='home_title_right_contrainer_title'>
                  Aperçu général
                </div>
                <div className='home_title_right_contrainer_subtitle'>
                  Retrouvez toutes vos études
                </div>
              </div>
            </div>
            <div className='home_statisticblock_contrainer'>
              <StudiesTable
                data={tableData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Studies;