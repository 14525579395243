import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  signOut
} from "firebase/auth";
import { useLocation, useNavigate } from 'react-router-dom';

import Logo from '../../assets/logo.png'

import { auth } from '../../App';

import './styles.css'

function logout() {
  signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
  });
}

const Navigation = () => {
  const [location, setLocation] = useState(useLocation())
  const navigate = useNavigate();

  return (
    <div className='navigator'>
      <div className='navigator_logo'>
        <img
          src={Logo}
          alt='logo'
        />
      </div>
      <div className='navigator_button_container'>
        <div
          className={location.pathname === "/" ? 'navigator_button navigator_button_selected' : 'navigator_button'}
          onClick={() => navigate('/')}
        >
          <div className='navigator_button_icon'>
            {location.pathname === "/" ? <FontAwesomeIcon icon={icon({name: 'grid-2', family: 'classic', style: 'solid'})} /> : <FontAwesomeIcon icon={icon({name: 'grid-2', family: 'classic', style: 'light'})} />}
          </div>
          <div className='navigator_button_text'>
            Tableau de bord
          </div>
        </div>
        <div
          className={location.pathname.startsWith("/mes-etudes") ? 'navigator_button navigator_button_selected' : 'navigator_button'}
          onClick={() => navigate('/mes-etudes')}
        >
          <div className='navigator_button_icon'>
            {location.pathname.startsWith("/mes-etudes") ? <FontAwesomeIcon icon={icon({name: 'chart-simple', family: 'classic', style: 'solid'})} /> : <FontAwesomeIcon icon={icon({name: 'chart-simple', family: 'classic', style: 'light'})} />}
          </div>
          <div className='navigator_button_text'>
            Mes études
          </div>
        </div>
        {/* <div
          className={location.pathname.startsWith("/mes-certificats") ? 'navigator_button navigator_button_selected' : 'navigator_button'}
          onClick={() => navigate('/mes-certificats')}
        >
          <div className='navigator_button_icon'>
            {location.pathname.startsWith("/mes-certificats") ? <FontAwesomeIcon icon={icon({name: 'badge-check', family: 'classic', style: 'solid'})} /> : <FontAwesomeIcon icon={icon({name: 'badge-check', family: 'classic', style: 'light'})} />}
          </div>
          <div className='navigator_button_text'>
            Mes certificats
          </div>
        </div> */}
      </div>
      <div className='navigator_footer'>
        <div className='navigator_footer_name'>
          {/* Mathilde Ortis (CTC) */}
        </div>
        <div
          className='navigator_button navigator_button_logout'
          onClick={() => logout()}
        >
          <div className='navigator_button_icon'>
            <FontAwesomeIcon icon={icon({name: 'arrow-right', family: 'sharp', style: 'regular'})} />
          </div>
          <div className='navigator_button_text'>
            Déconnexion
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navigation;