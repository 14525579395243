import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Timestamp } from "firebase/firestore";

import { getHomeStats } from '../services/firebase';

import './styles.css'

import Navigation from '../components/navigation';
import TextInput from '../components/textinput';
import Header from '../components/header';
import Button from '../components/button';
import StatisticBlock from '../components/statistics/block';
import StatisticGraph from '../components/statistics';

import Logo from '../assets/logo.png'

const graphLabels = [
  {
    text: "Réalisé",
    background: "#ACC1EA"
  },
  {
    text: "Validé",
    background: "#0076FE"
  },
]

const graphData = {
  labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet','Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
  datasets: [
    {
      data: [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
      ],
      backgroundColor: '#ACC1EA',
      borderWidth: 0,
      borderRadius: 5,
      categoryPercentage: 0.45,
      barPercentage: 1,
      maxBarThickness: 15,
    },
    {
      data: [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
      ],
      backgroundColor: '#0076FE',
      borderWidth: 0,
      borderRadius: 5,
      maxBarThickness: 15,
      categoryPercentage: 0.45,
      barPercentage: 1
    },
  ],
};

const Home = () => {
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({
    countPrevStudies: 0,
    countPrevTotalpower: 0,
    countPrevValidStudies: 0,
    countStudies: 0,
    countTotalpower: 0,
    countValidStudies: 0,
  })

  const actualmonth = new Date(Timestamp.now().seconds*1000)
  actualmonth.setHours(0);
  actualmonth.setMinutes(0);
  actualmonth.setSeconds(0);
  actualmonth.setDate(1)

  useEffect(() => {
    setLoading(true);
    getHomeStats(localStorage.getItem("userId"))
    .then((res) => {
      console.log(res)
      setValues(res)
      setLoading(false);
    })
    .catch((err) => {
      console.log("Une erreur est survenue", err)
    })
  }, []);
  
  return (
    <div className='home'>
      <div className='home_container'>
        <Navigation/>
        <div className='home_right_container'>
          <Header/>
          <div className='home_block'>
            <div className='home_title_contrainer'>
              <div className='home_title_right_contrainer'>
                <div className='home_title_right_contrainer_title'>
                  Hey !
                </div>
                <div className='home_title_right_contrainer_subtitle'>
                  Retrouve tes statistiques juste ici
                </div>
              </div>
            </div>
            <div className='home_statisticblock_contrainer'>
              <StatisticBlock
                title={"Études réalisées"}
                titleicon={<FontAwesomeIcon icon={icon({name: 'chart-simple', family: 'classic', style: 'regular'})} />}
                value={values.countStudies}
                time={"month"}
                ratio={(((values.countStudies / values.countPrevStudies) * 100) - 100)}
                loading={loading}
              />
              <StatisticBlock
                title={"Dossiers validés"}
                titleicon={<FontAwesomeIcon icon={icon({name: 'pen', family: 'sharp', style: 'regular'})} />}
                value={values.countValidStudies}
                time={"month"}
                ratio={((values.countValidStudies / values.countPrevValidStudies) * 100) - 100}
                loading={loading}
              />
              <StatisticBlock
                title={"Puissance installée"}
                titleicon={<FontAwesomeIcon icon={icon({name: 'bolt', family: 'sharp', style: 'regular'})} />}
                value={values.countTotalpower * 1000}
                time={"month"}
                ratio={((values.countTotalpower / values.countPrevTotalpower) * 100) - 100}
                loading={loading}
              />
            </div>
            <div className='home_title_contrainer'>
              <div className='home_title_right_contrainer'>
                <div className='home_title_right_contrainer_title'>
                  Statistiques générales
                </div>
                <div className='home_title_right_contrainer_subtitle'>
                  Retrouvez vos statistiques cumulés
                </div>
              </div>
            </div>
            <div className='home_statisticblock_contrainer'>
              <StatisticGraph
                loading={loading}
                title="Études réalisés / Dossiers validés"
                dateselector
                time={"year"}
                data={graphData}
                label={graphLabels}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home;