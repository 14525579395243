import { functions, db } from "../App";
import { httpsCallable } from "firebase/functions";
import { doc, updateDoc, getCountFromServer, getAggregateFromServer, query, where, collection, Timestamp, sum } from "firebase/firestore";

export const createSimulation = () => {
  const fetch = httpsCallable(functions, 'createsimulation');
  return fetch()
}

export const updateSimulationCustomer = async (docid, data) => {
  const docRef = doc(db, "simulation", docid);
  return await updateDoc(docRef, {
    step: 2,
    info: data
  });
}

export const updateSimulationConsumption = async (docid, data) => {
  const docRef = doc(db, "simulation", docid);
  return await updateDoc(docRef, {
    step: 3,
    consumption: data
  });
}

export const updateSimulationRecommended = async (docid, data) => {
  const docRef = doc(db, "simulation", docid);
  return await updateDoc(docRef, {
    step: 4,
    recommended: data
  });
}

export const getRecommendedkit = (data) => {
  const fetch = httpsCallable(functions, 'calculrecommendedkit');
  return fetch(data)
}

export const updateSimulationRoofs = async (docid, data) => {
  const fetch = httpsCallable(functions, 'updateroofs');
  return fetch({ docid: docid, data: data})
}

export const updateSimulationResults = async (docid) => {
  const docRef = doc(db, "simulation", docid);
  return await updateDoc(docRef, {
    step: 6,
    status: 1,
  });
}

export const updateSimulationContractLink = async (docid, data) => {
  const docRef = doc(db, "simulation", docid);
  return await updateDoc(docRef, {
    contractlink: data,
  });
}

export const updateSimulationStatus = async (docid, data) => {
  const docRef = doc(db, "simulation", docid);
  return await updateDoc(docRef, {
    status: data,
  });
}

export const getHomeStats = async (userId) => {
  const actualmonth = new Date(Timestamp.now().seconds*1000)
  actualmonth.setHours(0);
  actualmonth.setMinutes(0);
  actualmonth.setSeconds(0);
  actualmonth.setDate(1);

  const prevmonth = new Date(actualmonth);
  prevmonth.setMonth(prevmonth.getMonth() - 1)

  const coll = collection(db, "simulation");
  const studies = query(coll, where("creator", "==", userId), where("createdAt", ">=", Timestamp.fromDate(actualmonth)));
  const validstudies = query(coll, where("creator", "==", userId), where("createdAt", ">=", Timestamp.fromDate(actualmonth)), where("status", "==", 2));
  const totalpower = query(coll, where("creator", "==", userId), where("createdAt", ">=", Timestamp.fromDate(actualmonth)), where("status", "==", 2));
  const countStudies = await getCountFromServer(studies)
  const countValidStudies = await getCountFromServer(validstudies).catch((err) => console.log(err));
  const countTotalpower = await getAggregateFromServer(totalpower, {
    totalpower: sum('roofs.totalpower')
  }).catch((err) => console.log(err))

  const prevstudies = query(coll, where("creator", "==", userId), where("createdAt", ">=", Timestamp.fromDate(prevmonth)), where("createdAt", "<", Timestamp.fromDate(actualmonth)));
  const prevvalidstudies = query(coll, where("creator", "==", userId), where("status", "==", 2), where("createdAt", ">=", Timestamp.fromDate(prevmonth)), where("createdAt", "<", Timestamp.fromDate(actualmonth)));
  const prevtotalpower = query(coll, where("creator", "==", userId), where("createdAt", ">=", Timestamp.fromDate(prevmonth)), where("createdAt", "<", Timestamp.fromDate(actualmonth)), where("status", "==", 2));
  const countPrevStudies = await getCountFromServer(prevstudies);
  const countPrevValidStudies = await getCountFromServer(prevvalidstudies).catch((err) => console.log(err));
  const countPrevTotalpower = await getAggregateFromServer(prevtotalpower, {
    totalpower: sum('roofs.totalpower')
  }).catch((err) => console.log(err));

  return {
    countStudies: countStudies.data().count,
    countValidStudies: countValidStudies.data().count,
    countTotalpower: countTotalpower.data().totalpower,
    countPrevStudies: countPrevStudies.data().count,
    countPrevValidStudies: countPrevValidStudies.data().count,
    countPrevTotalpower: countPrevTotalpower.data().totalpower
  }
}