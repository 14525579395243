import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Timestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

import { useNavigate } from 'react-router-dom';

import { storage } from '../../../App';

import Button from '../../../components/button';
import SimulatorNavigation from '../../../components/simulator/navigation';
import StatisticBlock from '../../../components/statistics/block';
import StatisticGraph from '../../../components/statistics';

import { useParams, useLoaderData } from "react-router-dom";

import { updateSimulationResults, updateSimulationContractLink } from '../../../services/firebase';

import SimulationHeader from '../../../components/simulator/header';

import './styles.css'

const graphLabels = [
  {
    text: "Avant installation",
    background: "#C4C4C4"
  },
  {
    text: "Après installation",
    background: "#0076FE"
  },
]

const increase = 0.045;

const SimulatorResults = () => {
  let params = useParams();
  let data = useLoaderData();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    production: data.roofs.production || 0,
    totalpower: data.roofs.totalpower || 0,
    bonus:  data.roofs.bonus || 0,
    numberpanels: data.roofs.numberpanels || 0
  });

  let pdfBytes;

  function getOrientation(value) {
    let returnValue;
    switch (value) {
      case "180":
        returnValue = "Nord";
        break;
      case "-135":
        returnValue = "Nord-Est";
        break;
      case "-90":
        returnValue = "Est";
        break;
      case "-45":
        returnValue = "Sud-Est";
        break;
      case "0":
        returnValue = "Sud";
        break;
      case "45":
        returnValue = "Sud-Ouest";
        break;
      case "90":
        returnValue = "Ouest";
        break;
      case "135":
        returnValue = "Nord-Ouest";
        break;
      default:
        returnValue = "Sud";
    }

    return returnValue;
  }
  
  async function createPdf() {

    const url = 'https://firebasestorage.googleapis.com/v0/b/simulator-hanvolt.appspot.com/o/Rapport.pdf?alt=media&token=5b947642-276c-4fe8-ae0a-f3e5f7aa7a87'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    const secondPage = pages[1]
    const { width, height } = firstPage.getSize();

    let consumptionRatio = (data.roofs.production / data.consumption.consumption) * 100;
    consumptionRatio = consumptionRatio.toFixed(0);

    let presence;
    switch (data.info.presence) {
      case 1:
        presence = "Toute la journée";
        break;
      case 2:
        presence = "Matin, midi et soir";
        break;
      case 3:
        presence = "Matin et soir";
        break;
      default:
        presence = "Toute la journée";
    }

    const elecprice = 0.251;

    let roof1 = "";
    let roof2 = "";
    let roof3 = "";
    let roof4 = "";

    if (data.roofs.installation[0].numberpanel !== "") {
      roof1 = data.roofs.installation[0].numberpanel + " panneaux, Inclinaison " + data.roofs.installation[0].incline + "°, Orientation " + getOrientation(data.roofs.installation[0].orientation)
    }
    if (data.roofs.installation[1].numberpanel !== "") {
      roof2 = data.roofs.installation[1].numberpanel + " panneaux, Inclinaison " + data.roofs.installation[1].incline + "°, Orientation " + getOrientation(data.roofs.installation[1].orientation)
    }
    if (data.roofs.installation[2].numberpanel !== "") {
      roof3 = data.roofs.installation[2].numberpanel + " panneaux, Inclinaison " + data.roofs.installation[2].incline + "°, Orientation " + getOrientation(data.roofs.installation[2].orientation)
    }
    if (data.roofs.installation[3].numberpanel !== "") {
      roof4 = data.roofs.installation[3].numberpanel + " panneaux, Inclinaison " + data.roofs.installation[3].incline + "°, Orientation " + getOrientation(data.roofs.installation[3].orientation)
    }



    firstPage.drawText(`${data.info.name}, voici les résultats de votre simulation`, {
      x: 62,
      y: height - 170,
      size: 16,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })

    firstPage.drawText(`${data.roofs.production.toFixed(0)} kWh`, {
      x: 80,
      y: height - 288,
      size: 28,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(`${data.roofs.totalpower.toFixed(3)} kWc`, {
      x: 320,
      y: height - 288,
      size: 28,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(`${data.roofs.numberpanels.toFixed(0)} panneaux photovoltaiques`, {
      x: 320,
      y: height - 310,
      size: 10,
      font: helveticaFont,
      color: rgb(0.33333, 0.33333, 0.37647),
    })
    firstPage.drawText(`${data.roofs.bonus.toFixed(2)} €`, {
      x: 80,
      y: height - 424,
      size: 28,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(`${consumptionRatio} %`, {
      x: 320,
      y: height - 424,
      size: 28,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(`${new Date(Timestamp.now().seconds*1000).toLocaleDateString("fr-FR")}`, {
      x: 424,
      y: height - 482.5,
      size: 8,
      font: helveticaFont,
      color: rgb(0.69412, 0.67059, 0.6902),
    })
    firstPage.drawText('Hanvolt vous remercie pour votre écoute.', {
      x: 62,
      y: height - 686,
      size: 16,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    })
    secondPage.drawText(`${data.info.name}`, {
      x: 282,
      y: height - 197,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    secondPage.drawText(`${data.info.phone}`, {
      x: 282,
      y: height - 217,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    secondPage.drawText(`${data.info.email}`, {
      x: 282,
      y: height - 235,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    secondPage.drawText(`${data.info.address.label}`, {
      x: 282,
      y: height - 254,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    secondPage.drawText(`${data.info.people}`, {
      x: 282,
      y: height - 276,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    secondPage.drawText(`${presence}` , {
      x: 282,
      y: height - 296,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    secondPage.drawText(`${data.consumption.consumption} kWh`, {
      x: 282,
      y: height - 317,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    secondPage.drawText(`${data.roofs.totalpower.toFixed(3)} kWc`, {
      x: 282,
      y: height - 397,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    secondPage.drawText(`${data.roofs.production.toFixed(0)} kWh/an`, {
      x: 282,
      y: height - 418,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0.09412, 0.59216, 0.04314),
    })
    secondPage.drawText('soit environ', {
      x: 350,
      y: height - 418,
      size: 8,
      font: helveticaFont,
      color: rgb(0.69412, 0.67059, 0.6902),
    })
    secondPage.drawText(`${(data.roofs.production * elecprice).toFixed(2)} €/an`, {
      x: 394,
      y: height - 418,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0.09412, 0.59216, 0.04314),
    })
    secondPage.drawText(`${(data.roofs.production / data.roofs.totalpower).toFixed(0)} kWh/kWc`, {
      x: 282,
      y: height - 437,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    secondPage.drawText(`${elecprice} €`, {
      x: 392,
      y: height - 457,
      size: 8,
      font: helveticaFont,
      color: rgb(0.69412, 0.67059, 0.6902),
    })
    secondPage.drawText(`${roof1}`, {
      x: 282,
      y: height - 532,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    secondPage.drawText(`${roof2}`, {
      x: 282,
      y: height - 552,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    secondPage.drawText(`${roof3}`, {
      x: 282,
      y: height - 572,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    secondPage.drawText('QUATRIÈME PAN', {
      x: 62,
      y: height - 590,
      size: 9,
      font: helveticaFont,
      color: rgb(0.68627,0.67843,0.79216),
    })
    secondPage.drawText(`${roof4}`, {
      x: 282,
      y: height - 590,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    secondPage.drawText('10 ans', {
      x: 282,
      y: height - 658,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })
    secondPage.drawText(`${data.contract}`, {
      x: 282,
      y: height - 678,
      size: 9,
      font: helveticaBoldFont,
      color: rgb(0, 0, 0),
    })


    pdfDoc.setAuthor('Hanvolt');
    pdfDoc.setTitle(`Garantie_${data.info.name}`)
    pdfDoc.setCreationDate(new Date(Timestamp.now().seconds*1000))
    pdfBytes = await pdfDoc.save();
    const storageRef = ref(storage, `simulation/${params.id}/Garantie_${data.info.name}.pdf`);
    
    return uploadBytes(storageRef, pdfBytes, {contentType: "application/pdf"})
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref)
          .then((downloadURL) => {
            return updateSimulationContractLink(params.id, downloadURL);
          })
          .catch(() => {
            console.log("Une erreur est survenue");
          })
      })
      .catch(() => {
        console.log("Une erreur est survenue");
      })
  }

  const [year, setYear] = useState(Timestamp.fromDate(new Date()).toDate().getFullYear());

  const [graphData, setGraphData] = useState({
    labels: [year, year + 1, year + 2, year + 3, year + 4, year + 5, year + 6, year + 7, year + 8, year + 9 ],
    datasets: [
      {
        data: [
          (data.consumption.consumption * 0.23).toFixed(0),
          (data.consumption.consumption * 0.23 * 1.045).toFixed(0),
          (data.consumption.consumption * 0.23 * 1.09).toFixed(0),
          (data.consumption.consumption * 0.23 * 1.135).toFixed(0),
          (data.consumption.consumption * 0.23 * 1.18).toFixed(0),
          (data.consumption.consumption * 0.23 * 1.225).toFixed(0),
          (data.consumption.consumption * 0.23 * 1.27).toFixed(0),
          (data.consumption.consumption * 0.23 * 1.315).toFixed(0),
          (data.consumption.consumption * 0.23 * 1.36).toFixed(0),
          (data.consumption.consumption * 0.23 * 1.405).toFixed(0),
        ],
        backgroundColor: '#C4C4C4',
        borderWidth: 0,
        borderRadius: 5,
        categoryPercentage: 0.45,
        barPercentage: 1,
        maxBarThickness: 15,
      },
      {
        data: [
          ((data.consumption.consumption - (data.roofs.production * 0.7)) * 0.23).toFixed(0),
          (((data.consumption.consumption) - (data.roofs.production * 0.7)) * 0.23 * (1 + increase)).toFixed(0),
          (((data.consumption.consumption) - (data.roofs.production * 0.7)) * 0.23 * (1 + (increase * 2))).toFixed(0),
          (((data.consumption.consumption) - (data.roofs.production * 0.7)) * 0.23 * (1 + (increase * 3))).toFixed(0),
          (((data.consumption.consumption) - (data.roofs.production * 0.7)) * 0.23 * (1 + (increase * 4))).toFixed(0),
          (((data.consumption.consumption) - (data.roofs.production * 0.7)) * 0.23 * (1 + (increase * 5))).toFixed(0),
          (((data.consumption.consumption) - (data.roofs.production * 0.7)) * 0.23 * (1 + (increase * 6))).toFixed(0),
          (((data.consumption.consumption) - (data.roofs.production * 0.7)) * 0.23 * (1 + (increase * 7))).toFixed(0),
          (((data.consumption.consumption) - (data.roofs.production * 0.7)) * 0.23 * (1 + (increase * 8))).toFixed(0),
          (((data.consumption.consumption) - (data.roofs.production * 0.7)) * 0.23 * (1 + (increase * 9))).toFixed(0),
        ],
        backgroundColor: '#0076FE',
        borderWidth: 0,
        borderRadius: 5,
        maxBarThickness: 15,
        categoryPercentage: 0.45,
        barPercentage: 1
      },
    ],
  });


  return (
    <div className='simulator'>
      {loading && (
        <div className='simulator_loading'>
          <FontAwesomeIcon icon={icon({name: 'spinner-third', family: 'classic', style: 'duotone'})} />
        </div>
      )}
      <div className='simulator_container'>
        <SimulatorNavigation
          step={data.step}
        />
        <div className='simulator_container_right'>
          <SimulationHeader />
          <div className='simulator_container_display'>
            <div className='simulator_statisticblock_contrainer'>
              <StatisticBlock
                title={"Production anuelle"}
                titleicon={<FontAwesomeIcon icon={icon({name: 'chart-simple', family: 'classic', style: 'light'})} />}
                value={`${(values.production).toFixed(0)} kWh`}
                text={<span><span style={{color: "#26A81B", fontWeight: 500}}>Garantie</span> ou remboursée</span>}
                color={"#26A81B"}
                backgroundColor={"#EFFFF6"}
              />
              <StatisticBlock
                title={"Votre projet solaire"}
                titleicon={<FontAwesomeIcon icon={icon({name: 'bolt', family: 'sharp', style: 'solid'})} />}
                value={`${(values.totalpower).toFixed(3)} kWc`}
                text={`${(values.numberpanels).toFixed(0)} panneaux photovoltaïques`}
              />
              <StatisticBlock
                title={"Prime à l’auto-conso"}
                titleicon={<FontAwesomeIcon icon={icon({name: 'euro-sign', family: 'classic', style: 'solid'})} />}
                value={`${(values.bonus).toFixed(2)} €`}
                text={"Perçu 1 an seulement après l’installation"}
              />
            </div>
            <div className='simulator_container_form_title'>
              Votre consommation sur 10 ans
            </div>
            <div className='simulator_container_form_subtitle'>
              Le tarif de l'électricité connaît une hausse annuelle, avec une augmentation moyenne estimée entre 3 et 6 % par an
            </div>
            <div className='home_statisticblock_contrainer'>
              <StatisticGraph
                title={
                  <>
                    Avant / Après installation photovoltaïque 
                    <span style={{fontSize: "16px", fontWeight: 300, fontStyle: "italic"}}> (70% en auto-consommation)</span>
                  </>
                }
                data={graphData}
                label={graphLabels}
                euro
              />
            </div>
            <div className='simulator_container_form_button_container'>
              <Button
                text={"Précédent"}
                color={"#404040"}
                background={"#EEEEEE"}
                type={"button"}
                onClick={() => navigate(-1)}
              />
              <Button
                text={"Suivant"}
                color={"#FFFFFF"}
                background={"#0076FE"}
                type={"button"}
                onClick={async() => {
                  setLoading(true);
                  createPdf()
                    .then(() => {
                      updateSimulationResults(params.id)
                        .then(() => {
                          setLoading(false);
                          navigate(`/simulation/${params.id}/certificate`)
                        })
                    })
                    .catch(() => {
                      console.log("Une erreur est survenue")
                    })
                  }
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimulatorResults;