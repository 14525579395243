import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { useNavigate } from 'react-router-dom';

import Button from '../../../components/button';
import TextInput from '../../../components/textinput';
import SimulatorNavigation from '../../../components/simulator/navigation';
import Selector from '../../../components/selector';
import SimulatorDisplayData from '../../../components/simulator/displayData';

import { useParams, useLoaderData } from "react-router-dom";

import { updateSimulationConsumption } from '../../../services/firebase';

import SimulationHeader from '../../../components/simulator/header';

import './styles.css'

const contractData = [
  {
    value: true,
    text: "Heure pleines / Heure creuses",
  },
  {
    value: false,
    text: "Base",
  },
]

const counterData = [
  {
    value: true,
    text: "Monophasé",
  },
  {
    value: false,
    text: "Triphasé",
  },
]

const SimulatorConsumption = () => {
  let params = useParams();
  let data = useLoaderData();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const [values, setValues] = useState({
    invoice: data.consumption.invoice || "",
    consumption: data.consumption.consumption || "",
    contract: data.consumption.contract || true,
    counter: data.consumption.counter || true,
  });

  function onChangeInvoice(value) {
    let newvalue = value / 0.2516
    newvalue = newvalue.toFixed(0)
    setValues({...values, consumption: newvalue, invoice: value})
  }

  function onChangeConsumption(value) {
    let newvalue = value * 0.2516
    newvalue = newvalue.toFixed(0)
    setValues({...values, consumption: value, invoice: newvalue})
  }

  return (
    <div className='simulator'>
      {loading && (
        <div className='simulator_loading'>
          <FontAwesomeIcon icon={icon({name: 'spinner-third', family: 'classic', style: 'duotone'})} />
        </div>
      )}
      <div className='simulator_container'>
        <SimulatorNavigation
          step={data.step}
        />
        <div className='simulator_container_right'>
          <SimulationHeader />
          <form
            className='simulator_container_form'
            onSubmit={(e) => {
              e.preventDefault();
              const updatedData = values;
              updatedData.consumption = parseInt(updatedData.consumption)
              updatedData.invoice = parseInt(updatedData.invoice)
              setLoading(true);
              updateSimulationConsumption(params.id, updatedData)
              .then((res) => {
                setTimeout(() => {
                  setLoading(false);
                  navigate(`/simulation/${params.id}/recommendation`);
                }, 1000);
              })
              .catch((err) => {
                setLoading(false);
                alert('Une erreur est survenue')
              })
            }}
          >
            <div className='simulator_container_form_left'>
              <div className='simulator_container_form_title'>
                Identité du maître d'ouvrage
              </div>
              <div className='simulator_container_form_subtitle'>
                Veuillez renseigner l'identité du maître d'ouvrage
              </div>
              <div className='simulator_container_form_input_container'>
                <TextInput
                  label={"Je connais la facture annuelle"}
                  value={values.invoice}
                  onChange={(e) => onChangeInvoice(e.target.value)}
                  placeholder={"1500.00"}
                  type={"number"}
                  iconvalue={"€"}
                  pattern={"^[0-9]*$"}
                  required
                />
                <TextInput
                  label={"Je connais la consommation annuelle"}
                  value={values.consumption}
                  onChange={(e) => onChangeConsumption(e.target.value)}
                  placeholder={"4000"}
                  type={"number"}
                  iconvalue={"kWh"}
                  pattern={"^[0-9]*$"}
                  required
                />
                <Selector
                  label={"Type de contrat d'électricité"}
                  data={contractData}
                  value={values.contract}
                  onChange={(e) => setValues({...values, contract: e.target.value})}
                  required
                />
                <Selector
                  label={"Type de compteur"}
                  data={counterData}
                  value={values.counter}
                  onChange={(e) => setValues({...values, counter: e.target.value})}
                  required
                />
              </div>
              <div className='simulator_container_form_button_container'>
                <Button
                  text={"Précédent"}
                  color={"#404040"}
                  background={"#EEEEEE"}
                  type={"button"}
                  onClick={() => navigate(-1)}
                />
                <Button
                  text={"Suivant"}
                  color={"#FFFFFF"}
                  background={"#0076FE"}
                  type={"submit"}
                />
              </div>
            </div>
            <div className='simulator_container_form_right'>
              <div className='simulator_container_form_title'>
                Estimation de votre consommation
              </div>
              <div className='simulator_container_form_subtitle'>
                Veuillez renseigner les informations de consommations
              </div>
              <SimulatorDisplayData
                titleicon={<FontAwesomeIcon icon={icon({name: 'chart-simple', family: 'classic', style: 'light'})} />}
                title={"Consommation moyenne à l'année"}
                value={values.consumption + " kWh"}
                text={<span><span style={{color: "#0076FE", fontWeight: "500"}}>{values.invoice} € </span> cette année</span>}
                bottomtext={"* Estimation calculée sur la base d'un prix moyen de 25.16 centimes d'euro par kWh électrique"}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SimulatorConsumption;