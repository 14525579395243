import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import './styles.css'

const Button = ({text, type, disabled, onClick, background, color, iconleft, iconright}) => {
  return (
    <button
      className='button'
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={{
        background: background,
        color: color
      }}
    >
      {iconleft && (
        <>
          {iconleft}
        </>
      )}
      <div className='button_text'>
        {text}
      </div>
      {iconright && (
         <>
          {iconright}
        </>
      )}
    </button>
  )
}

export default Button;