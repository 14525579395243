import React, { useState } from 'react';
import './App.css';

import {
  getApp,
  initializeApp 
} from "firebase/app";
import {
  getFunctions,
  connectFunctionsEmulator
} from "firebase/functions";
import {
  getAuth,
  connectAuthEmulator,
  onAuthStateChanged
} from "firebase/auth";
import {
  getStorage,
  connectStorageEmulator
} from "firebase/storage";
import {
  getFirestore,
  connectFirestoreEmulator,
  getDoc,
  doc
} from "firebase/firestore";


import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
  Navigate
} from "react-router-dom";

import Home from './pages';
import Studies from './pages/studies';
import Login from './pages/login';
import SimulatorCustomer from './pages/simulator';
import SimulatorConsumption from './pages/simulator/consumption';
import SimulatorRecommendation from './pages/simulator/recommendation';
import SimulatorRoofs from './pages/simulator/roofs';
import SimulatorResults from './pages/simulator/results';
import SimulatorCertificate from './pages/simulator/certificate';

const firebaseConfig = {
  apiKey: "AIzaSyCVq70OYjhHaVHyV6sSrvR9UHz68CymDQg",
  authDomain: "simulator-hanvolt.firebaseapp.com",
  projectId: "simulator-hanvolt",
  storageBucket: "simulator-hanvolt.appspot.com",
  messagingSenderId: "572873045238",
  appId: "1:572873045238:web:953d817d3c887fbb7da8d7"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

const fetchSimulation = async (id) => {
  const docRef = doc(db, "simulation", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    throw new Response("Not Found", { status: 404 });
  }
}


function App() {
  const [isLoggedIn, setIsloggedIn] = useState(localStorage.getItem('isloggedin'));

  onAuthStateChanged(auth, (user) => {
    if (user) {
      localStorage.setItem('isloggedin', true)
      localStorage.setItem('userId', user.uid)
      setIsloggedIn(true)
    } else {
      localStorage.setItem('isloggedin', false)
      localStorage.setItem('userId', undefined)
      setIsloggedIn(false)
    }
  });


  const router = createBrowserRouter([
    {
      path: "/login",
      element: isLoggedIn === true ? <Navigate to="/" replace={true}/> : <Login />,
    },
    {
      path: "/",
      element: isLoggedIn === false ? <Navigate to="/login" replace={true}/> : <Home />,
    },
    {
      path: "/mes-etudes",
      element: isLoggedIn === false ? <Navigate to="/login" replace={true}/> : <Studies />,
    },
    {
      path: "/simulation/:id/informations",
      element: isLoggedIn === false ? <Navigate to="/" replace={true}/> : <SimulatorCustomer />,
      loader: async ({ params }) => {
        return fetchSimulation(params.id);
      },
    },
    {
      path: "/simulation/:id/consumption",
      element: isLoggedIn === false ? <Navigate to="/" replace={true}/> : <SimulatorConsumption />,
      loader: async ({ params }) => {
        return fetchSimulation(params.id);
      },
    },
    {
      path: "/simulation/:id/recommendation",
      element: isLoggedIn === false ? <Navigate to="/" replace={true}/> : <SimulatorRecommendation />,
      loader: async ({ params }) => {
        return fetchSimulation(params.id);
      },
    },
    {
      path: "/simulation/:id/roofs",
      element: isLoggedIn === false ? <Navigate to="/" replace={true}/> : <SimulatorRoofs />,
      loader: async ({ params }) => {
        return fetchSimulation(params.id);
      },
    },
    {
      path: "/simulation/:id/results",
      element: isLoggedIn === false ? <Navigate to="/" replace={true}/> : <SimulatorResults />,
      loader: async ({ params }) => {
        return fetchSimulation(params.id);
      },
    },
    {
      path: "/simulation/:id/certificate",
      element: isLoggedIn === false ? <Navigate to="/" replace={true}/> : <SimulatorCertificate />,
      loader: async ({ params }) => {
        return fetchSimulation(params.id);
      },
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
