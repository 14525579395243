export const getYearlyProduction = async (position, data) => {
  let finalData = 0;
  return Promise.all(data.map( async (roof) => {
    if (roof.totalpower > 0) {
      const reponse = await fetch(`https://re.jrc.ec.europa.eu/api/PVcalc?lat=${position.lat}&lon=${position.lng}&peakpower=${roof.totalpower}&loss=14&angle=${roof.incline}&aspect=${roof.orientation}&outputformat=json`, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT",
          // "Content-Security-Policy": "default-src 'self' https://europa.eu https://*.europa.eu; object-src 'none'; script-src 'self' 'unsafe-inline' 'unsafe-eval' https://europa.eu https://*.europa.eu; style-src 'self' 'unsafe-inline' data: https://europa.eu https://*.europa.eu; font-src 'self' data: https://europa.eu https://*.europa.eu; img-src 'self' data: https://*.europa.eu https://tiles.maps.eox.at; frame-ancestors 'self' https://visitors-centre.jrc.ec.europa.eu;",
          "Content-Type": "application/json"
        }
      });
      const responseData = await reponse.json();
      finalData = finalData + responseData.outputs.totals.fixed["E_y"];
    }
  }))
  .then(() => {
    return finalData
  })
  .catch((err) => console.log(err))
}