import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import './styles.css'

function timevalue(value) {
  let displayText = "";
  switch (value) {
    case 'week':
      displayText = "Cette semaine";
      break;
    case 'month':
      displayText = "Ce mois-ci";
      break;
    case 'year':
      displayText = "Cette année";
      break;
    case 'all':
      displayText = "Au total";
      break;
    default:
      displayText = "";
  }
  return displayText;
}

const StatisticBlock = ({title, value, ratio, titleicon, time, loading, onselecttime, text, useSelector, color, backgroundColor}) => {
  const [selector, setSelector] = useState(false)

  return (
    <div className='statisticblock'>
      {loading ? (
        <>
          <div className='statisticblock_title_container'>
            <Skeleton width={200} height={34}/>
          </div>
          <div className='statisticblock_value'>
            <Skeleton width={100} height={50}/>
          </div>
          <div className='statisticblock_ratio_container'>
            <Skeleton width={150} height={20}/>
          </div>
        </>
      ) : (
        <>
        {useSelector && (
          <>
            <div className='statisticblock_edit' onClick={() => selector ? setSelector(false) : setSelector(true)}>
              <FontAwesomeIcon icon={icon({name: 'ellipsis-vertical', family: 'sharp', style: 'regular'})} />
              {selector && (
                <div className='statisticblock_edit_selector'>
                  <select size="5" value={time} onChange={onselecttime}>
                    <option value={"week"}>Cette Semaine</option>
                    <option value={"month"}>Ce mois-ci</option>
                    <option value={"year"}>Cette année</option>
                    <option value={"all"}>Au total</option>
                  </select>
                </div>
              )}
            </div>
          </>
        )}
          <div className='statisticblock_title_container'>
            <div
              className='statisticblock_title_icon'
              style={{
                background: backgroundColor,
                color: color
              }}
            >
              {titleicon}
            </div>
            <div className='statisticblock_title_text'>
              {title}
            </div>
          </div>
          <div className='statisticblock_value'>
            {value}
          </div>
          <div className='statisticblock_ratio_container'>
            {typeof(ratio) === "number" && (
              <>
                {ratio >= 0 ? (
                  <div className='statisticblock_ratio statisticblock_ratio_plus'>
                    + {ratio} %
                  </div>
                ) : (
                  <div className='statisticblock_ratio statisticblock_ratio_minus'>
                    - {ratio * -1} %
                  </div>
                )}
              </>
            )}
            {time && (
              <>
                {timevalue(time)}
              </>
            )}
            {text && (
              <>
                {text}
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default StatisticBlock;