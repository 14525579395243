import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import TextInput from '../../textinput';
import Selector from '../../selector';

import './styles.css'

const orientations = [
  {
    text: "Nord",
    value: 180
  },
  {
    text: "Nord-Est",
    value: -135
  },
  {
    text: "Est",
    value: -90
  },
  {
    text: "Sud-Est",
    value: -45
  },
  {
    text: "Sud",
    value: 0
  },
  {
    text: "Sud-Ouest",
    value: 45
  },
  {
    text: "Ouest",
    value: 90
  },
  {
    text: "Nord-Ouest",
    value: 135
  },
]

const SimulatorEditRoof = ({
  selected,
  onClick,
  power,
  totalpower,
  numberpanel,
  onChangeNumberPanel,
  incline,
  onChangeIncline,
  orientation,
  onChangeOrientation,
  order,
}) => {

  function displayOrder(value) {
    let newValue;
    switch (value) {
      case 0:
        newValue = "premier";
        break;
      case 1:
        newValue = "deuxième";
        break;
      case 2:
        newValue = "troisième";
        break;
      case 3:
        newValue = "quatrième";
        break;
      default:
        newValue = "dernier";
    }
    return newValue
  }

  return (
    <div className='simulatoreditroof'>
      <div className='simulatoreditroof_title_container' onClick={onClick}>
        <div className='simulatoreditroof_title_left'>
          <div className='simulatoreditroof_title_icon'>
            <FontAwesomeIcon icon={icon({name: 'house', family: 'classic', style: 'regular'})} />
          </div>
          <div className='simulatoreditroof_title'>
            Description du {displayOrder(order)} pan
          </div>
        </div>
        <div className={selected ? 'simulatoreditroof_title_right simulatoreditroof_title_right_selected' : 'simulatoreditroof_title_right'}>
          {selected ? (
            <FontAwesomeIcon icon={icon({name: 'angle-up', family: 'sharp', style: 'solid'})} />
          ) : (
            <FontAwesomeIcon icon={icon({name: 'angle-down', family: 'sharp', style: 'solid'})} />
          )}
        </div>
      </div>
      <div className={selected ? 'simulatoreditroof_input_container simulatoreditroof_input_container_selected' : 'simulatoreditroof_input_container'}>
        <div className='simulatoreditroof_input'>
          <TextInput
            label={"Nombre de panneaux"}
            value={numberpanel}
            onChange={onChangeNumberPanel}
            placeholder={"0"}
            type={"number"}
            pattern={"^[0-9]*$"}
            min={1}
            required
          />
          <TextInput
            label={"Puissance par panneaux (Wc)"}
            value={power}
            disabled
            required
          />
          <TextInput
            label={"Inclinaison (°)"}
            value={incline}
            onChange={onChangeIncline}
            type={"number"}
            pattern={"^[0-9]*$"}
            required
          />
          <Selector
            label={"Orientation"}
            data={orientations}
            value={orientation}
            onChange={onChangeOrientation}
            required
          />
          <TextInput
            label={"Puissance totale (kWc)"}
            value={totalpower}
            disabled
            required
          />
        </div>
      </div>
    </div>
  )
}

export default SimulatorEditRoof;