import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import './styles.css'

const SimulatorDisplayKit = ({
  titleicon,
  title,
  size,
  economy,
  color,
  background,
  panel,
  numberpanel,
  inverter,
  area,
  guaranteePanel,
  guaranteeProduction,
  guaranteeInverter,
  hotwater,
  loading,
  selected,
  onClick
}) => {
  return (
    <div
      className='simulatordisplaykit'
      onClick={onClick}
      style={{
        backgroundColor: selected && background,
        borderColor: selected && color
      }}
    >
      {loading ? (
          <Skeleton height={381} width={238} borderRadius={10}/>
      ) : (
        <>
          <div className='simulatordisplaykit_title_container'>
            <div className='simulatordisplaykit_title_icon' style={{background: color}}>
              {titleicon}
            </div>
            <div className='simulatordisplaykit_title'>
              {title}
            </div>
          </div>
          <div className='simulatordisplaykit_size'>
            {size.toFixed(3)} kWc
          </div>
          <div className='simulatordisplaykit_economy'>
            <span className='simulatordisplaykit_economy_value' style={{color: color}}>+ {economy} €</span> par an maximum
          </div>
          <div className='simulatordisplaykit_data_container'>
            <div className='simulatordisplaykit_data'>
              <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})} color={color}/>
              {numberpanel} {panel}
            </div>
            <div className='simulatordisplaykit_data'>
              <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})} color={color}/>
              {inverter}
            </div>
            <div className='simulatordisplaykit_data'>
              <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})} color={color}/>
              Surface d'environ {area} m²
            </div>
            <div className='simulatordisplaykit_data'>
              <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})} color={color}/>
              Panneaux garantis {guaranteePanel} ans
            </div>
            <div className='simulatordisplaykit_data'>
              <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})} color={color}/>
              Onduleur garantie {guaranteeInverter} ans
            </div>
            {guaranteeProduction !== false ? (
              <div className='simulatordisplaykit_data'>
                <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})} color={color}/>
                  Garantie de production {guaranteeProduction} ans
              </div>
            ) : (
              <div className='simulatordisplaykit_data'>
                <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})} color={"#D30000"}/>
                <span className='simulatordisplaykit_data_notallowed'>Garantie de production</span>
              </div>
            )}
            {hotwater !== false ? (
              <div className='simulatordisplaykit_data'>
                <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})} color={color}/>
                Optimisation ECS 500€ (inclus)
              </div>
            ) : (
              <div className='simulatordisplaykit_data'>
                <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})} color={"#9C9C9C"}/>
                <span className='simulatordisplaykit_data_option'>Optimisation ECS (en option)</span>
              </div>
            )}
          </div>
        </>
      )}
      
    </div>
  )
}

export default SimulatorDisplayKit;