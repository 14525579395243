import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

import download from 'downloadjs';

import { useNavigate } from 'react-router-dom';

import Button from '../../../components/button';
import SimulatorNavigation from '../../../components/simulator/navigation';

import { useParams, useLoaderData } from "react-router-dom";

import { font as GorditaMedium } from '../../../fonts/GorditaJs/Gordita-Bold-normal';

import SimulationHeader from '../../../components/simulator/header';

import './styles.css'

const SimulatorCertificate = () => {
  let params = useParams();
  let data = useLoaderData();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [pdf, setPdf] = useState();
  // download(pdfBytes, "pdf-lib_modification_example.pdf", "application/pdf");/

  return (
    <div className='simulator'>
      {loading && (
        <div className='simulator_loading'>
          <FontAwesomeIcon icon={icon({name: 'spinner-third', family: 'classic', style: 'duotone'})} />
        </div>
      )}
      <div className='simulator_container'>
        <SimulatorNavigation
          step={data.step}
        />
        <div className='simulator_container_right'>
          <SimulationHeader />
          <div className='simulator_container_display'>
            <div className='simulator_container_form_title'>
              Téléchargez vos résultats
            </div>
            <div className='simulator_container_form_subtitle'>
              Téléchargez votre certificat de production officiel
            </div>
            <div className='simulator_container_form_button_container'>
              <Button
                text={"Précédent"}
                color={"#404040"}
                background={"#EEEEEE"}
                type={"button"}
                onClick={() => navigate(-1)}
              />
              <Button
                text={"Télécharger le certificat"}
                color={"#FFFFFF"}
                background={"#FF4920"}
                type={"button"}
                onClick={() => download(data.contractlink,"test", "application/pdf")}
              />
            </div>
            <div className='simulator_pdf_container'>
              <iframe src={data.contractlink} width="100%" height="740px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimulatorCertificate;