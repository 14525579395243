import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  APIProvider,
  Map,
  Marker
} from '@vis.gl/react-google-maps';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';

import { useNavigate } from 'react-router-dom';

import SimulationHeader from '../../components/simulator/header';
import Button from '../../components/button';
import TextInput from '../../components/textinput';
import SimulatorNavigation from '../../components/simulator/navigation';
import Selector from '../../components/selector';
import Slider from '../../components/slider';

import { useParams, useLoaderData } from "react-router-dom";

import { updateSimulationCustomer } from '../../services/firebase';

import './styles.css'



const presenceData = [
  {
    value: 1,
    text: "Toute la journée",
  },
  {
    value: 2,
    text: "Matin, midi et soir",
  },
  {
    value: 3,
    text: "Matin et soir",
  },
]


const SimulatorCustomer = () => {
  let params = useParams();
  let data = useLoaderData();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const [values, setValues] = useState({
    title: data.info.title || "",
    name: data.info.name || "",
    phone: data.info.phone || "",
    email: data.info.email || "",
    address: data.info.address || "",
    people: data.info.people || 1,
    presence: data.info.presence || 1,
  });

  const [coordinates, setCoordinates] = useState({
    lat: data.info.lat || 47.8249046208979,
    lng: data.info.lng || 2.61878695312962,
    zoom: data.info.zoom || 6,
    marker: data.step >= 2 ? true : false
  });

  const [moveCoordinates, setMoveCoordinates] = useState({
    lat: data.info.lat || 47.8249046208979,
    lng: data.info.lng || 2.61878695312962,
    zoom: data.info.zoom || 6,
  });

  function getGeocode(value) {
    geocodeByPlaceId(value)
      .catch(error => alert(error))
      .then(res => {
        console.log("geo", res)
        if (res[0].geometry.bounds) {
          setCoordinates({...coordinates, lat: res[0].geometry.bounds.ci.hi, lng: res[0].geometry.bounds.Fh.hi, zoom: 19, marker: true});
          setMoveCoordinates({...coordinates, lat: res[0].geometry.bounds.ci.hi, lng: res[0].geometry.bounds.Fh.hi, zoom: 19});
        } else {
          setCoordinates({...coordinates, lat: res[0].geometry.viewport.ci.hi, lng: res[0].geometry.viewport.Fh.hi, zoom: 19, marker: true});
          setMoveCoordinates({...coordinates, lat: res[0].geometry.viewport.ci.hi, lng: res[0].geometry.viewport.Fh.hi, zoom: 19});  
        }
      })
  }

  return (
    <div className='simulator'>
      {loading && (
        <div className='simulator_loading'>
          <FontAwesomeIcon icon={icon({name: 'spinner-third', family: 'classic', style: 'duotone'})} />
        </div>
      )}
      <div className='simulator_container'>
        <SimulatorNavigation
          step={data.step}
        />
        <div className='simulator_container_right'>
          <SimulationHeader />
          <form
            className='simulator_container_form'
            onSubmit={(e) => {
              e.preventDefault();
              const updatedData = {...values, ...coordinates}
              setLoading(true);
              updateSimulationCustomer(params.id, updatedData)
              .then((res) => {
                setTimeout(() => {
                  setLoading(false);
                  navigate(`/simulation/${params.id}/consumption`);
                }, 1000);
              })
              .catch((err) => {
                setLoading(false);
                alert('Une erreur est survenue')
              })
            }}
          >
            <div className='simulator_container_form_left'>
              <div className='simulator_container_form_title'>
                Identité du maître d'ouvrage
              </div>
              <div className='simulator_container_form_subtitle'>
                Veuillez renseigner l'identité du maître d'ouvrage
              </div>
              <div className='simulator_container_form_input_container'>
                <TextInput
                  label={"Titre"}
                  value={values.title}
                  onChange={(e) => setValues({...values, title: e.target.value})}
                  placeholder={"Étude photovoltaïque"}
                  type={"text"}
                  required
                />
                <TextInput
                  label={"Nom complet"}
                  value={values.name}
                  onChange={(e) => setValues({...values, name: e.target.value})}
                  placeholder={"Philibert Leclerc"}
                  type={"text"}
                  required
                />
                <TextInput
                  label={"Numéro de téléphone"}
                  value={values.phone}
                  onChange={(e) => setValues({...values, phone: e.target.value})}
                  placeholder={"06 45 24 55 60"}
                  type={"tel"}
                  required
                />
                <TextInput
                  label={"E-mail"}
                  value={values.email}
                  onChange={(e) => setValues({...values, email: e.target.value})}
                  placeholder={"philibert.leclerc85@gmail.com"}
                  type={"email"}
                  required
                />
              </div>
              <div className='simulator_container_form_title'>
                Adresse du logement
              </div>
              <div className='simulator_container_form_subtitle'>
                Veuillez renseigner l'adresse du maître d'ouvrage
              </div>
              <div className='simulator_container_form_input_container'>
                <TextInput
                  label={"Adresse complète"}
                  placeholder={"Rechercher..."}
                  value={values.address}
                  defaultValue={values.address}
                  onChange={(e) => {
                    setValues({...values, address: e});
                    getGeocode(e.value.place_id);
                  }}
                  maps
                  required
                />
              </div>
              <div className='simulator_container_form_title'>
                Informations sur le logement
              </div>
              <div className='simulator_container_form_subtitle'>
                Veuillez renseigner les informations sur le logement
              </div>
              <div className='simulator_container_form_input_container'>
                <div className='simulator_container_form_input_slider'>
                  <TextInput
                    label={"Nombre de personnes dans le logement"}
                    value={values.people + " personnes"}
                    disabled
                    required
                  />
                  <Slider
                    min={1}
                    max={10}
                    value={values.people}
                    onChange={(e) => setValues({...values, people: e})}
                  />
                </div>
                <Selector
                  label={"Profil de présence dans le logement"}
                  data={presenceData}
                  value={values.presence}
                  onChange={(e) => setValues({...values, presence: e.target.value})}
                  required
                />
              </div>
              <div className='simulator_container_form_button_container'>
                <Button
                  text={"Précédent"}
                  color={"#404040"}
                  background={"#EEEEEE"}
                  type={"button"}
                  onClick={() => navigate(-1)}
                />
                <Button
                  text={"Suivant"}
                  color={"#FFFFFF"}
                  background={"#0076FE"}
                  type={"submit"}
                />
              </div>
            </div>
            <div className='simulator_container_form_right'>
              <div className='simulator_container_form_maps'>
                <APIProvider apiKey={"AIzaSyD-iFNaBwX8GtQ0qmS7MkZ7UMvkgMIj-GI"}>
                  <Map
                    defaultCenter={{
                      lat: 47.8249046208979,
                      lng: 2.61878695312962
                    }}
                    defaultZoom={6}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                    mapTypeId={"hybrid"}
                    center={{
                      lat: moveCoordinates.lat,
                      lng: moveCoordinates.lng
                    }}
                    zoom={moveCoordinates.zoom}
                    onDblclick={(value) => setCoordinates({...coordinates, lat: value.detail.latLng.lat, lng: value.detail.latLng.lng})}
                    onCameraChanged={(value) => setMoveCoordinates({...coordinates, lat: value.detail.center.lat, lng: value.detail.center.lng, zoom: value.detail.zoom})}
                  >
                    {coordinates.marker && (
                      <Marker
                        position={{
                          lat: coordinates.lat,
                          lng: coordinates.lng
                        }}
                      />
                    )}
                  </Map>
                </APIProvider>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SimulatorCustomer;