import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import './styles.css'

const Selector = ({label, data, onChange, value, required}) => {
  return (
    <div className='selector'>
      {label && (
        <div className='selector_label'>
          {label}
          {required && (
            <div className='selector_label_required'>
              *
            </div>
          )}
        </div>
      )}
      <select className='selector_input' value={value} onChange={onChange}>
        {typeof(data) === "object" && (
          <>
            {data.map((item) => (
              <option value={item.value}>{item.text}</option>
            ))}
          </>
        )}
      </select>
    </div>
  )
}

export default Selector;