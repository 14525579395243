import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom';

import TextInput from '../../components/textinput';
import Button from '../../components/button';

import { createSimulation } from '../../services/firebase';

import './styles.css'

const Header = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)

  return (
    <div className='header'>
      {loading && (
        <div className='header_loading'>
          <FontAwesomeIcon icon={icon({name: 'spinner-third', family: 'classic', style: 'duotone'})} />
        </div>
      )}
      <div className='header_search'>
        <TextInput
          type={"text"}
          placeholder={"Rechercher..."}
          icon={<FontAwesomeIcon icon={icon({name: 'magnifying-glass', family: 'classic', style: 'regular'})} />}
        />
      </div>
      <div className='header_button_container'>
        <div className='header_notify_button'>
          <FontAwesomeIcon icon={icon({name: 'bell', family: 'classic', style: 'regular'})} />
          <div className='header_notify_pin'/>
        </div>
        <Button
          text={"Simulation immédiate"}
          color={"#FFFFFF"}
          background={"#031556"}
          iconright={<FontAwesomeIcon icon={icon({name: 'plus', family: 'classic', style: 'regular'})} />}
          onClick={() => {
            setLoading(true)
            createSimulation()
            .then((res) => {
              setLoading(false)
              navigate(`/simulation/${res.data.id}/informations`)
            })
            .catch((err) => {
              setLoading(false)
            })
          }}
        />
      </div>
    </div>
  )
}

export default Header;