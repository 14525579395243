import React, { useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { updateSimulationStatus } from '../../services/firebase';


import './styles.css'

const StudiesTable = ({loading, data}) => {
  const [rows, setRows] = useState([])
  const navigate = useNavigate();

  const columns = [
    {
      id: 'customer',
      label: 'Nom du client',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'phone',
      label: 'Téléphone',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'status',
      label: 'Statut',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'power',
      label: 'Puissance',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'production',
      label: 'Production',
      minWidth: 100,
      align: 'left',
      format: (value) => value.toFixed(2),
    },
    {
      id: 'date',
      label: 'Date',
      minWidth: 100,
      align: 'left',
    },
  ];

  function createData(customer, phone, status, power, production, date) {
    return { customer, phone, status, power, production, date };
  }

  function getRows() {
    const newRows = [];
    data.forEach((doc) => {
      newRows.push(createData(doc.data().info.name, doc.data().info.phone, [doc.data().status, doc.id], doc.data().roofs.totalpower.toFixed(3) + ' kWc', doc.data().roofs.production, [new Date(doc.data().createdAt.seconds*1000).toLocaleDateString("fr-FR"), doc.id]))
    });
    setRows(newRows)
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getRows();
  }, [data]);


  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: "10px", border: "1px solid #DFDFDF", boxShadow: "none", fontSize: "14px" }}>
      <TableContainer sx={{ height: "calc(100vh - 360px)" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    borderLeft: "1px solid #DFDFDF",
                    fontWeight: "400",
                    color:"#555560",
                    fontFamily: "Gordita",
                    padding: "20px"
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.id === "production") {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              borderLeft: "1px solid #DFDFDF",
                              fontWeight: "500",
                              color:"#383838",
                              fontFamily: "Gordita",
                              padding: "20px",
                              width: "180px"
                            }}
                          >
                            <div className='studiestable_summary'>
                              <FontAwesomeIcon icon={icon({name: 'eye', family: 'classic', style: 'regular'})}/>
                              <span>Voir le récapitulatif</span>
                            </div>
                          </TableCell>
                        )
                      } else if (column.id === "status") {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              borderLeft: "1px solid #DFDFDF",
                              fontWeight: "500",
                              color:"#383838",
                              fontFamily: "Gordita",
                              padding: "20px"
                            }}
                          >
                            <div className='studiestable_status'>
                              <Select
                                value={value[0]}
                                // onChange={(e) => {
                                //   updateSimulationStatus(e.target.value ,value[1])
                                // }}
                                className='studiestable_status_select'
                                sx={{
                                  fontSize: '12px',
                                  borderRadius: "50px",
                                  width: "100%",
                                  fontFamily: 'Gordita',
                                  fontWeight: 500,
                                  color: (value[0] === 1 && "#FE8900") || (value[0] === 2 && "#26A81B") || (value[0] === 3 && "#D30000"),
                                  borderColor: (value[0] === 1 && "#FE8900") || (value[0] === 2 && "#26A81B") || (value[0] === 3 && "#D30000"),
                                  background: (value[0] === 1 && "#FFF8ED") || (value[0] === 2 && "#EFFFF1") || (value[0] === 3 && "#FFEFEF")
                                }}
                              >
                                <MenuItem value={0}>À compléter</MenuItem>
                                <MenuItem style={{ color: "#FE8900", backgroundColor: "#FFF8ED" }} value={1}>En attente</MenuItem>
                                <MenuItem style={{ color: "#26A81B", backgroundColor: "#EFFFF1" }} value={2}>Validé</MenuItem>
                                <MenuItem style={{ color: "#D30000", backgroundColor: "#FFEFEF" }} value={3}>Refusé</MenuItem>
                              </Select>
                            </div>
                          </TableCell>
                        );
                      } else if (column.id === "date") {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              borderLeft: "1px solid #DFDFDF",
                              fontWeight: "500",
                              color:"#383838",
                              fontFamily: "Gordita",
                              padding: "20px"
                            }}
                          >
                            <div className='studiestable_row'>
                              {value[0]}
                              <div className='studiestable_row_edit' title='Modifier' onClick={() => navigate(`/simulation/${value[1]}/informations`)}>
                                <FontAwesomeIcon icon={icon({name: 'pen-to-square', family: 'sharp', style: 'regular'})}/>
                              </div>
                            </div>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              borderLeft: "1px solid #DFDFDF",
                              fontWeight: "500",
                              color:"#383838",
                              fontFamily: "Gordita",
                              padding: "20px"
                            }}
                          >
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Ligne par page"
        labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) { return `${from}–${to} de ${count !== -1 ? count : `more than ${to}`}`; }}
        sx={{ borderTop: "1px solid #DFDFDF", boxSizing: "border-box" }}
      />
    </Paper>
  )
}

export default StudiesTable;