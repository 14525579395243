import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { useNavigate } from 'react-router-dom';

import Button from '../../../components/button';
import SimulatorNavigation from '../../../components/simulator/navigation';
import SimulatorDisplayKit from '../../../components/simulator/displayKit';

import { useParams, useLoaderData } from "react-router-dom";

import {
  getRecommendedkit,
  updateSimulationRecommended
} from '../../../services/firebase';

import SimulationHeader from '../../../components/simulator/header';

import './styles.css'

const SimulatorRecommendation = () => {
  let params = useParams();
  let data = useLoaderData();
  const navigate = useNavigate();
  const [values, setValues] = useState([
    {}, {}, {}
  ])
  const [selected, setSelected] = useState(data.recommended.kit)
  const [kitLoading, setKitLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  function loadKits(){
    setKitLoading(true)
    getRecommendedkit({id: params.id})
    .then((res) => {
      setValues(res.data);
      setKitLoading(false);
    })
    .catch((err) => {
      alert("Une erreur est survenue")
    })
  }

  useEffect(() => {
    loadKits()
  }, []);

  return (
    <div className='simulator'>
      {loading && (
        <div className='simulator_loading'>
          <FontAwesomeIcon icon={icon({name: 'spinner-third', family: 'classic', style: 'duotone'})} />
        </div>
      )}
      <div className='simulator_container'>
        <SimulatorNavigation
          step={data.step}
        />
        <div className='simulator_container_right'>
          <SimulationHeader />
          <form
            className='simulator_container_form'
            onSubmit={(e) => {
              e.preventDefault();
              const updatedData = {
                power: values[selected].power,
                kit: selected
              };
              setLoading(true);
              updateSimulationRecommended(params.id, updatedData)
              .then((res) => {
                setTimeout(() => {
                  setLoading(false);
                  navigate(`/simulation/${params.id}/roofs`);
                }, 1000);
              })
              .catch((err) => {
                setLoading(false);
  
                alert('Une erreur est survenue')
              })
            }}
          >
            <div className='simulator_container_form_full'>
              <div className='simulator_container_form_title'>
                Puissances recommandées
              </div>
              <div className='simulator_container_form_subtitle'>
                Veillez à bien contrôler techniquement que ce type d'installation est réalisable
              </div>
              <div className='simulator_container_form_kit_container'>
                {values.map((item) => {
                  return (
                    <SimulatorDisplayKit
                      key={item.priority}
                      titleicon={<FontAwesomeIcon icon={icon({name: 'bolt', family: 'sharp', style: 'solid'})} />}
                      title={item.title}
                      size={(item.power * item.numberpanel) / 1000}
                      color={item.color}
                      economy={(item.power * item.numberpanel * 1.3 * 0.2516).toFixed(0)}
                      panel={item.panel}
                      numberpanel={item.numberpanel}
                      inverter={item.inverter}
                      area={item.area}
                      guaranteePanel={item.guarantee_panel}
                      guaranteeProduction={item.guarantee_production}
                      guaranteeInverter={item.guarantee_inverter}
                      hotwater={item.hotwater}
                      loading={kitLoading}
                      onClick={() => setSelected(item.priority)}
                      selected={selected === item.priority}
                      background={item.background}
                    />
                  )
                })}
              </div>
              <div className='simulator_container_form_button_container'>
                <Button
                  text={"Précédent"}
                  color={"#404040"}
                  background={"#EEEEEE"}
                  type={"button"}
                  onClick={() => navigate(-1)}
                />
                <Button
                  text={"Suivant"}
                  color={"#FFFFFF"}
                  background={"#0076FE"}
                  type={"submit"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SimulatorRecommendation;