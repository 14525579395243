import React from 'react';

import './styles.css'

const SimulatorDisplayData = ({titleicon, title, value, text, data, subtext, color, background, bottomtext}) => {
  return (
    <div className='simulatordisplaydata'>
      <div className='simulatordisplaydata_title_container'>
        <div className='simulatordisplaydata_title_icon'>
          {titleicon}
        </div>
        <div className='simulatordisplaydata_title'>
          {title}
        </div>
      </div>
      <div className='simulatordisplaydata_value'>
        {value}
      </div>
      <div className='simulatordisplaydata_text'>
        {text} {subtext && (<> / {subtext}</>)}
      </div>
      {data && (
        <div className='simulatordisplaydata_data_container'>
          {typeof(data) === "object" && (
            <>
              {data.map((item) => (
                <div className='simulatordisplaydata_data'>
                  {item.icon}
                  {item.text}
                </div>
              ))}
            </>
          )}
        </div>
      )}
      <div className='simulatordisplaydata_bottomtext'>
        {bottomtext}
      </div>
    </div>
  )
}

export default SimulatorDisplayData;