import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { useNavigate } from 'react-router-dom';

import Button from '../../../components/button';
import SimulatorEditRoof from '../../../components/simulator/editRoof';
import SimulatorNavigation from '../../../components/simulator/navigation';
import Selector from '../../../components/selector';
import SimulatorDisplayData from '../../../components/simulator/displayData';

import { useParams, useLoaderData } from "react-router-dom";

import { updateSimulationRoofs } from '../../../services/firebase';
import { getYearlyProduction } from '../../../services/pvgis';

import SimulationHeader from '../../../components/simulator/header';

import './styles.css'

const numberRoofs = [
  {
    text: "1 pan",
    value: 1
  },
  {
    text: "2 pans",
    value: 2
  },
  {
    text: "3 pans",
    value: 3
  },
  {
    text: "4 pans",
    value: 4
  },
]

const SimulatorRoofs = () => {
  let params = useParams();
  let data = useLoaderData();
  const navigate = useNavigate();
  const position = {
    lat: data.info.lat,
    lng: data.info.lng,
  };
  const consumption = data.consumption.consumption;
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(false)

  const [totalpower, setTotalPower] = useState(data.roofs.totalpower || 0)

  const [values, setValues] = useState({
    production: data.roofs.production || 0,
    numberroofs: data.roofs.numberroofs || 1,
    numberpanels: data.roofs.numberpanels || 0,
  });

  const [roof1, setRoof1] = useState(data.roofs.installation[0] ||
  {
    numberpanel: "",
    power: data.recommended.power,
    incline: 20,
    orientation: 0,
    totalpower: 0,
  });;
  const [roof2, setRoof2] = useState(data.roofs.installation[1] ||
  {
    numberpanel: "",
    power: data.recommended.power,
    incline: 20,
    orientation: 0,
    totalpower: 0,
  });
  const [roof3, setRoof3] = useState(data.roofs.installation[2] ||
    {
      numberpanel: "",
      power: data.recommended.power,
      incline: 20,
      orientation: 0,
      totalpower: 0,
    });
  const [roof4, setRoof4] = useState(data.roofs.installation[3] ||
  {
    numberpanel: "",
    power: data.recommended.power,
    incline: 20,
    orientation: 0,
    totalpower: 0,
  });

  function onChangeNumberRoof(value) {
    const defaultroof = {
      numberpanel: "",
      power: data.recommended.power,
      incline: 20,
      orientation: 0,
      totalpower: 0,
    }
    if (value === "1") {
      setRoof2(defaultroof);
      setRoof3(defaultroof);
      setRoof4(defaultroof);
    } else if (value === "2") {
      setRoof3(defaultroof);
      setRoof4(defaultroof);
    }
    else if (value === "3") {
      setRoof4(defaultroof);
    }
  }

  function updateTotalpower() {
    let newvalue = roof1.totalpower +  roof2.totalpower +  roof3.totalpower +  roof4.totalpower;
    setTotalPower(newvalue);
  }

  function updateNumberPanels() {
    let introof1 = parseInt(roof1.numberpanel) || 0;
    let introof2 = parseInt(roof2.numberpanel) || 0;
    let introof3 = parseInt(roof3.numberpanel) || 0;
    let introof4 = parseInt(roof4.numberpanel) || 0;
    let newvalue = introof1 + introof2 + introof3 + introof4;
    setValues({...values, numberpanels: newvalue});
  }

  async function getProduction() {
    if (totalpower > 0) {
      let data = []
      if (values.numberroofs >= 1) {
        data.push(roof1);
      }
      if (values.numberroofs >= 2) {
        data.push(roof2);
      }
      if (values.numberroofs >= 3) {
        data.push(roof3);
      }
      if (values.numberroofs >= 4) {
        data.push(roof4);
      }
      getYearlyProduction(position, data)
      .then((res) => {
        if (res !== undefined) {
          setValues({...values, production: res})
        }
      })
    }
  }

  useEffect(() => {
      updateTotalpower()
  }, [roof1, roof2, roof3, roof4]);

  useEffect(() => {
      getProduction()
  }, [totalpower, roof1.incline, roof1.orientation, roof2.incline, roof2.orientation, roof3.incline, roof3.orientation, roof4.incline, roof4.orientation]);

  useEffect(() => {
    updateNumberPanels()
  }, [roof1.numberpanel, roof2.numberpanel, roof3.numberpanel, roof4.numberpanel]);

  useEffect(() => {
    setRoof1({...roof1, power: data.recommended.power});
    setRoof2({...roof2, power: data.recommended.power});
    setRoof3({...roof3, power: data.recommended.power});
    setRoof4({...roof4, power: data.recommended.power});
  }, []);


  return (
    <div className='simulator'>
      {loading && (
        <div className='simulator_loading'>
          <FontAwesomeIcon icon={icon({name: 'spinner-third', family: 'classic', style: 'duotone'})} />
        </div>
      )}
      <div className='simulator_container'>
        <SimulatorNavigation
          step={data.step}
        />
        <div className='simulator_container_right'>
          <SimulationHeader />
          <form
            className='simulator_container_form'
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              const newdata = {
                numberroofs: values.numberroofs,
                totalpower: totalpower,
                installation: [roof1, roof2, roof3, roof4],
                position: position,
                numberpanels: values.numberpanels
              }
              updateSimulationRoofs(params.id, newdata)
              .then((res) => {
                setTimeout(() => {
                  setLoading(false);
                  navigate(`/simulation/${params.id}/results`);
                }, 1000);
                })
                .catch((err) => {
                  alert('Une erreur est survenue');
                })
            }}
          >
            <div className='simulator_container_form_left'>
              <div className='simulator_container_form_title'>
                Configuration de la toiture
              </div>
              <div className='simulator_container_form_subtitle'>
                Veuillez saisir la configuration de l’installation
              </div>
              <div className='simulator_container_form_input_container'>
                <Selector
                  label={"Nombre de pan disponible "}
                  data={numberRoofs}
                  value={values.numberroofs}
                  onChange={(e) => {
                    setValues({...values, numberroofs: e.target.value});
                    onChangeNumberRoof(e.target.value);
                  }}
                  required
                />
              </div>
              <div className='simulator_container_form_title'>
                Description de l’installation
              </div>
              <div className='simulator_container_form_subtitle'>
                Calpinage de votre installation
              </div>
              {values.numberroofs >= 1 && (
                <SimulatorEditRoof
                  order={0}
                  selected={selected === 0}
                  onClick={() => selected === 0 ? setSelected(false) : setSelected(0)}
                  orientation={roof1.orientation}
                  numberpanel={roof1.numberpanel}
                  power={roof1.power}
                  incline={roof1.incline}
                  totalpower={roof1.totalpower.toFixed(3)}
                  onChangeNumberPanel={(e) => {
                    setRoof1({...roof1, numberpanel: e.target.value, totalpower: ((roof1.power * e.target.value / 1000))});
                  }}
                  onChangeOrientation={(e) => {
                    setRoof1({...roof1, orientation: e.target.value});
                  }}
                  onChangeIncline={(e) => {
                    setRoof1({...roof1, incline: e.target.value});
                  }}
                />
              )}
              {values.numberroofs >= 2 && (
                <SimulatorEditRoof
                  order={1}
                  selected={selected === 1}
                  onClick={() => selected === 1 ? setSelected(false) : setSelected(1)}
                  orientation={roof2.orientation}
                  numberpanel={roof2.numberpanel}
                  power={roof2.power}
                  incline={roof2.incline}
                  totalpower={roof2.totalpower.toFixed(3)}
                  onChangeNumberPanel={(e) => {
                    setRoof2({...roof2, numberpanel: e.target.value, totalpower: ((roof2.power * e.target.value / 1000))});
                  }}
                  onChangeOrientation={(e) => {
                    setRoof2({...roof2, orientation: e.target.value});
                  }}
                  onChangeIncline={(e) => {
                    setRoof2({...roof2, incline: e.target.value});
                  }}
                />
              )}
              {values.numberroofs >= 3 && (
                <SimulatorEditRoof
                  order={2}
                  selected={selected === 2}
                  onClick={() => selected === 2 ? setSelected(false) : setSelected(2)}
                  orientation={roof3.orientation}
                  numberpanel={roof3.numberpanel}
                  power={roof3.power}
                  incline={roof3.incline}
                  totalpower={roof3.totalpower.toFixed(3)}
                  onChangeNumberPanel={(e) => {
                    setRoof3({...roof3, numberpanel: e.target.value, totalpower: ((roof3.power * e.target.value / 1000))});
                  }}
                  onChangeOrientation={(e) => {
                    setRoof3({...roof3, orientation: e.target.value});
                  }}
                  onChangeIncline={(e) => {
                    setRoof3({...roof3, incline: e.target.value});
                  }}
                />
              )}
              {values.numberroofs >= 4 && (
                <SimulatorEditRoof
                  order={3}
                  selected={selected === 3}
                  onClick={() => selected === 3 ? setSelected(false) : setSelected(3)}
                  orientation={roof4.orientation}
                  numberpanel={roof4.numberpanel}
                  power={roof4.power}
                  incline={roof4.incline}
                  totalpower={roof4.totalpower.toFixed(3)}
                  onChangeNumberPanel={(e) => {
                    setRoof4({...roof4, numberpanel: e.target.value, totalpower: ((roof4.power * e.target.value / 1000))});
                  }}
                  onChangeOrientation={(e) => {
                    setRoof4({...roof4, orientation: e.target.value});
                  }}
                  onChangeIncline={(e) => {
                    setRoof4({...roof4, incline: e.target.value});
                  }}
                />
              )}
              <div className='simulator_container_form_button_container'>
                <Button
                  text={"Précédent"}
                  color={"#404040"}
                  background={"#EEEEEE"}
                  type={"button"}
                  onClick={() => navigate(-1)}
                />
                <Button
                  text={"Suivant"}
                  color={"#FFFFFF"}
                  background={"#0076FE"}
                  type={"submit"}
                />
              </div>
            </div>
            <div className='simulator_container_form_right'>
              <div className='simulator_container_form_title'>
                Estimation de votre consommation
              </div>
              <div className='simulator_container_form_subtitle'>
                Veuillez renseigner les informations de consommations
              </div>
              <div className='simulator_container_form_sticky'>
                <SimulatorDisplayData
                  titleicon={<FontAwesomeIcon icon={icon({name: 'bolt', family: 'sharp', style: 'solid'})} />}
                  title={"Puissance de la centrale photovoltaïque"}
                  value={totalpower.toFixed(3) + " kWc"}
                  text={<span><span style={{color: "#0076FE", fontWeight: "500", cursor: "pointer"}} onClick={() => getProduction()}>{values.production.toFixed(0)} kWh </span> de production par an</span>}
                  subtext={<span><span style={{color: "#555560", fontWeight: "500"}}>{(values.production * 0.2516).toFixed(2)} € </span>  maximum par an</span>}
                  data={[
                    {
                      icon: <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})}/>,
                      text: <span>{values.numberpanels} panneaux photovoltaïques haute puissance</span>
                    },
                    {
                      icon: <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})}/>,
                      text: <span>{((values.production * 100) / consumption).toFixed(0)} % de rendement annuel environ</span>
                    }
                  ]}
                  bottomtext={"* Cette estimation de production provient de sources officielles de la Commission Européenne."}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SimulatorRoofs;