import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import './styles.css'

const TextInput = ({label, type, placeholder, value, defaultValue, onChange, disabled, required, min, max, icon, maps, iconvalue, pattern}) => {
  return (
    <div className='textinput'>
      {label && (
        <div className='textinput_label'>
          {label}
          {required && (
            <div className='textinput_label_required'>
              *
            </div>
          )}
        </div>
      )}
      <div className='textinput_input_container' style={{backgroundColor: disabled && "#EDEDED"}}>
        {icon && (
          <div className='textinput_input_icon'>
            {icon}
          </div>
        )}
        {maps ? (
          <GooglePlacesAutocomplete
            apiKey='AIzaSyD-iFNaBwX8GtQ0qmS7MkZ7UMvkgMIj-GI'
            apiOptions={{
              language: 'fr',
              region: 'fr'
            }}
            selectProps={{
              onChange: onChange,
              placeholder: placeholder,
              value: value,
              required: required
            }}
          />
        ) : (
          <input
            className='textinput_input'
            type={type}
            placeholder={placeholder}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            disabled={disabled}
            required={required}
            min={min}
            max={max}
            pattern={pattern}
          />
        )}
        {iconvalue && (
          <div className='textinput_input_iconvalue'>
            {iconvalue}
          </div>
        )}
      </div>
    </div>
  )
}

export default TextInput;