import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useNavigate } from 'react-router-dom';

import TextInput from '../../../components/textinput';
import Button from '../../../components/button';

import { createSimulation } from '../../../services/firebase';

import EuropeImg from '../../../assets/simulator/europe.png'
import CountryImg from '../../../assets/simulator/country.png'

import './styles.css'

const SimulationHeader = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)

  return (
    <div className='simulationheader'>
      {loading && (
        <div className='simulationheader_loading'>
          <FontAwesomeIcon icon={icon({name: 'spinner-third', family: 'classic', style: 'duotone'})} />
        </div>
      )}
      <div className='simulationheader_top'>
        <div className='simulationheader_title'>
          Simulation officielle photovoltaïque avec Hanvolt
        </div>
        <div className='simulationheader_button_container'>
          <div id='simulationheader_button_settings'>
            <Button
              text={"Paramètres"}
              color={"#404040"}
              background={"#EEEEEE"}
              iconleft={<FontAwesomeIcon icon={icon({name: 'gear', family: 'classic', style: 'regular'})} />}
            />
          </div>
          <div id='simulationheader_button_save'>
            <Button
              text={"Sauvegarder le projet"}
              color={"#FFFFFF"}
              background={"#031556"}
            />
          </div>
        </div>
      </div>
      <div className='simulationheader_bottom'>
        <div className='simulationheader_subtitle'>
          Toutes les données numériques employées pour les analyses de rentabilité proviennent de sources officielles de la Commission Européenne.
        </div>
        <div className='simulationheader_image_container'>
          <img
            src={EuropeImg}
            alt='Logo commission europeenne'
          />
          <img
            src={CountryImg}
            alt='Logo nouvelle aquitaine'
          />
        </div>
      </div>
    </div>
  )
}

export default SimulationHeader;