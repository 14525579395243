import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import './styles.css'

function timevalue(value) {
  let displayText = "";
  switch (value) {
    case 'week':
      displayText = "Cette semaine";
      break;
    case 'month':
      displayText = "Ce mois-ci";
      break;
    case 'year':
      displayText = "Cette année";
      break;
    case 'all':
      displayText = "Au total";
      break;
    default:
      displayText = "";
  }
  return displayText;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
);


const StatisticGraph = ({data, dateselector, onselecttime, time, loading, label, euro, title}) => {

  return (
    <div className='statisticgraph'>
      {loading ? (
        <>
          <Skeleton height={470} style={{ width: "calc(100% - 50px)", boxSizing: "border-box", margin: "25px"}}/>
        </>
      ) : (
        <>
          <div className='statisticgraph_top'>
            <div className='statisticgraph_top_left'>
              <div className='statisticgraph_top_left_title'>
                {title}
              </div>
            </div>
            <div className='statisticgraph_top_right'>
              <div className='statisticgraph_top_right_color_container'>
                {typeof(label) === "object" && (
                  label.map((item) => (
                    <div className='statisticgraph_top_right_color'>
                      <div
                        style={{
                          background: item.background
                        }}
                        className='statisticgraph_top_right_color_circle'
                      />
                      <div className='statisticgraph_top_right_color_text'>
                        {item.text}
                      </div>
                    </div>
                  ))
                )}
              </div>
              {dateselector && (
                <div className='statisticgraph_top_right_selector_container'>
                  <div className='statisticgraph_top_right_selector'>
                    <FontAwesomeIcon icon={icon({name: 'calendar-days', family: 'sharp', style: 'solid'})} />
                    <select value={time} onChange={onselecttime}>
                      <option value={"week"}>Cette Semaine</option>
                      <option value={"month"}>Ce mois-ci</option>
                      <option value={"year"}>Cette année</option>
                      <option value={"all"}>Au total</option>
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='statisticgraph_bottom'>
            <Bar
              data={data}
              height={420}
              style={{
                height: "420px"
              }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  tooltip: {
                    intersect: false,
                    mode: "index",
                    bodySpacing: 15,
                    backgroundColor: "#25252A",
                    cornerRadius: 7,
                    yAlign: "center",
                    xAlign: "left",
                    boxWidth: 13,
                    boxHeight: 13,
                    boxPadding: 10,
                    usePointStyle: "circle",
                    padding: 30,
                    multiKeyBackground: "transparent",
                    callbacks: {
                      title : () => null,
                      label: function(context) {
                        if (euro) {
                          let label = context.dataset.label || '';
              
                          if (label) {
                            label += ': ';
                          }
                          if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat('fr-FR', {
                              style: 'currency',
                              currency: 'EUR',
                              maximumFractionDigits: 0
                            }).format(context.parsed.y);
                          }
                          return label;
                        }
                      }
                    },
                    bodyFont: {
                      size: 12,
                      weight: 500
                    },
                  }
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                    border: {
                      display: false
                    },
                    ticks: {
                      font: {
                        size: 12,
                      },
                      color: "#666D81",
                      padding: 25,
                    }
                  },
                  y: {
                    border: {
                      display: false,
                    },
                    grid: {
                      color: "#F5F5F7",
                    },
                    ticks: {
                      maxTicksLimit: 7,
                      font: {
                        size: 12,
                      },
                      color: "#666D81",
                      padding: 25
                    }
                  }
                }
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default StatisticGraph;