import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { signInWithEmailAndPassword } from "firebase/auth";

import { auth } from '../../App';


import Button from '../../components/button';
import TextInput from '../../components/textinput';

import './styles.css'

const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function QueryLogin() {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {

      })
      .catch((error) => {
        alert(error.code)
      });
  } 

  return (
    <div className='login'>
      <form
        className='login_form'
        onSubmit={(e) => {
          e.preventDefault();
          QueryLogin();
        }}
      >
        <TextInput
          type={"email"}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <TextInput
          type={"password"}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <Button
          text={"Connexion"}
          type={"submit"}
        />
      </form>
    </div>
  )
}

export default Login;