import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Logo from '../../../assets/logo.png';

import './styles.css'

const SimulatorNavigation = ({step}) => {
  const [location, setLocation] = useState(useLocation())
  let params = useParams();
  const navigate = useNavigate();

  return (
    <div className='simulatornavigator'>
      <div className='simulatornavigator_logo'>
        <img
          src={Logo}
          alt='logo'
          onClick={() => navigate("/mes-etudes")}
          title="Retour à l'accueil"
        />
      </div>
      <div className='simulatornavigator_button_container'>
        <>
          {step === 1 && (
            <div className='simulatornavigator_button simulatornavigator_button_selected' onClick={() => navigate(`/simulation/${params.id}/informations`)}>
              <FontAwesomeIcon icon={icon({name: 'circle', family: 'classic', style: 'regular'})} />
              Informations du logement
            </div>
          )}
          {step >= 2 && (
            <div className='simulatornavigator_button simulatornavigator_button_selected' onClick={() => navigate(`/simulation/${params.id}/informations`)}>
              <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})} />
              Informations du logement
            </div>
          )}
        </>
        <>
          {step < 2 && (
            <div className='simulatornavigator_button'>
              <FontAwesomeIcon icon={icon({name: 'circle', family: 'classic', style: 'regular'})} />
              Consommations
            </div>
          )}
          {step === 2 && (
            <div className='simulatornavigator_button simulatornavigator_button_selected' onClick={() => navigate(`/simulation/${params.id}/consumption`)}>
              <FontAwesomeIcon icon={icon({name: 'circle', family: 'classic', style: 'regular'})} />
              Consommations
            </div>
          )}
          {step > 2 && (
            <div className='simulatornavigator_button simulatornavigator_button_selected' onClick={() => navigate(`/simulation/${params.id}/consumption`)}>
              <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})} />
              Consommations
            </div>
          )}
        </>
        <>
          {step < 3 && (
            <div className='simulatornavigator_button'>
              <FontAwesomeIcon icon={icon({name: 'circle', family: 'classic', style: 'regular'})} />
              Recommandations
            </div>
          )}
          {step === 3 && (
            <div className='simulatornavigator_button simulatornavigator_button_selected' onClick={() => navigate(`/simulation/${params.id}/recommendation`)}>
              <FontAwesomeIcon icon={icon({name: 'circle', family: 'classic', style: 'regular'})} />
              Recommandations
            </div>
          )}
          {step > 3 && (
            <div className='simulatornavigator_button simulatornavigator_button_selected' onClick={() => navigate(`/simulation/${params.id}/recommendation`)}>
              <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})} />
              Recommandations
            </div>
          )}
        </>
        <>
          {step < 4 && (
            <div className='simulatornavigator_button'>
              <FontAwesomeIcon icon={icon({name: 'circle', family: 'classic', style: 'regular'})} />
              Transformation énergétique
            </div>
          )}
          {step === 4 && (
            <div className='simulatornavigator_button simulatornavigator_button_selected' onClick={() => navigate(`/simulation/${params.id}/roofs`)}>
              <FontAwesomeIcon icon={icon({name: 'circle', family: 'classic', style: 'regular'})} />
              Transformation énergétique
            </div>
          )}
          {step > 4 && (
            <div className='simulatornavigator_button simulatornavigator_button_selected' onClick={() => navigate(`/simulation/${params.id}/roofs`)}>
              <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})} />
              Transformation énergétique
            </div>
          )}
        </>
        <>
          {step < 5 && (
            <div className='simulatornavigator_button'>
              <FontAwesomeIcon icon={icon({name: 'circle', family: 'classic', style: 'regular'})} />
              Résultats
            </div>
          )}
          {step === 5 && (
            <div className='simulatornavigator_button simulatornavigator_button_selected' onClick={() => navigate(`/simulation/${params.id}/results`)}>
              <FontAwesomeIcon icon={icon({name: 'circle', family: 'classic', style: 'regular'})} />
              Résultats
            </div>
          )}
          {step > 5 && (
            <div className='simulatornavigator_button simulatornavigator_button_selected' onClick={() => navigate(`/simulation/${params.id}/results`)}>
              <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})} />
              Résultats
            </div>
          )}
        </>
        <>
          {step < 6 && (
            <div className='simulatornavigator_button'>
              <FontAwesomeIcon icon={icon({name: 'circle', family: 'classic', style: 'regular'})} />
              Certificat de production
            </div>
          )}
          {step === 6 && (
            <div className='simulatornavigator_button simulatornavigator_button_selected' onClick={() => navigate(`/simulation/${params.id}/certificate`)}>
              <FontAwesomeIcon icon={icon({name: 'circle-check', family: 'classic', style: 'solid'})} />
              Certificat de production
            </div>
          )}
        </>
      </div>
    </div>
  )
}

export default SimulatorNavigation;